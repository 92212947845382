.contact__button {
  width: 56px;
  height: 56px;
  border: none;
  margin-bottom: 16px;
  background-color: var(--secondary-color1);
}
.contact__link {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--primary-color);
}
.contact__icon {
  font-size: 24px;
  margin: 0;
}
