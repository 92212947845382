.intro__container {
  min-height: 100vh;
  max-width: calc((100% / 16) * 8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.intro__content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}
.intro__text {
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.intro__text h1 {
  font-family: var(--primary-font);
  font-size: 32px;
  font-weight: 600;
  color: var(--primary-font);
  margin-bottom: 0;
}
.intro__img {
  border-radius: 50%;
  overflow: hidden;
}
.intro__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tech__content {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 36px;
}
.tech__menu {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.tech__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-flow: row nowrap;
}
.tech__item {
  width: 36px;
  height: 36px;
  margin: 0 24px;
}
.tech__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
