.project__item {
  flex: 1;
  display: flex;
  align-items: center;
  align-items: center;
  max-width: calc((100% / 16) * 12);
  margin: 120px auto;
}

.project__item.reverse .project__description {
  text-align: end;
}
.project__description h3 {
  font-family: var(--primary-font);
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-color);
}
.project__description p {
  font-family: var(--secondary-font);
  font-size: 18px;
  font-weight: 400;
  color: var(--primary-color);
}
.project__img {
  flex: 1;
  width: 100%;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.project__img:hover {
  transform: scale(1.1);
}
.project__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes scaleUp {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
