.about__container {
  /* min-height: 100vh; */
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
.about__content {
  max-width: calc((100% / 16) * 12);
  padding: 36px;
  border-radius: 24px;
  box-shadow: 0 0 10px var(--secondary-color);
}
.content__detail {
  display: flex;
  flex-flow: row nowrap;
}
.text__content {
  margin: auto;
}
.text__content h2 {
  margin-top: 0;
  font-family: var(--primary-font);
  font-size: 24px;
  font-weight: 600;
}
.text__content p {
  font-family: var(--secondary-font);
  font-size: 18px;
  font-weight: 400;
  color: var(--primary-color);
}
.text__content i {
  font-family: var(--secondary-font);
  font-size: 18px;
  font-weight: 500;
  color: var(--secondary-color);
}
.img__content {
  width: 100%;
  height: 100%;
  /* background-image: url("../../../../assets/images/132726218_1896005157225297_7414236719923744195_n.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right 35% bottom 45%; */
  margin-right: 24px;
}
.img__content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
